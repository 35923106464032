<template>
  <div v-loading="loaders.videos">
    <add-video-modal @updateVideosEvent="updateVideos" :menuId="menuId"></add-video-modal>
    <edit-video-modal @updateVideosEvent="updateVideos" :id="editId" :menuId="menuId"></edit-video-modal>
    <div class="m-0 px-3 pt-4 pb-0">
      <div class="d-flex justify-content-between">
        <h1 class="m-0">Video URL</h1>
        <div class="d-flex justify-content-end align-items-center">
          <router-link class="ml-2" :to="{ name: 'ContentCategories',params:{menuTitle:'videos',menuId:menuId}}">
            <el-button
              size="md"
              class="title-btn-padding button-font white-outline-button button-hover-effect" type="secondary">
              Categories
            </el-button>
          </router-link>
          <router-link class="ml-2" :to="{ name: 'ContentSubCategories',params:{menuTitle:'videos',menuId:menuId}}">
            <el-button
              size="md"
              class="title-btn-padding button-font white-outline-button button-hover-effect" type="secondary">
              Sub Categories
            </el-button>
          </router-link>
        <base-button
          size="md"
          class="ml-2 title-btn-padding btn-secondary-action button-font"
          @click="toggleAddVideoModal" type="secondary">
          Add New
        </base-button>
        </div>
      </div>

      <div class="mt-4 cardStyle">
        <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
          <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                       @click="promptMultiRemove"
                       :loading="loaders.checkboxButton.Delete"
                       :disabled="loaders.videos"
          >Delete
          </base-button>
          <base-button class="mt-0 ml-2 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :loading="loaders.checkboxButton.Activate"
                       :disabled="loaders.videos"
          >Activate
          </base-button>
          <base-button class="mt-0 mb-3 ml-2 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :loading="loaders.checkboxButton.DeActive"
                       :disabled="loaders.videos"
          >Deactivate
          </base-button>
        </div>
        <el-table-draggable
          handle=".handle"
          @drop="updateSortList">
          <el-table
            class="table-responsive table-flush checklist-items-table checklist-table"
            header-row-class-name="thead-light"
            width="100%"
            ref="multipleSelectionTable"
            :data="response.data"
            @selection-change="videosMultiSelect">
            <el-table-column align="left" :class-name="btnDraggable" width="50">
              <template slot-scope="scope">
                <div class="handle" v-if="scope.row.id !==''">
                  <img src="/img/icons/buttons/handle.svg" alt="+">
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="selected" type="selection" min-width="130px">
            </el-table-column>
            <el-table-column label="Category" min-width="130px">
              <template slot-scope="scope">
                <div class="trim-text-overflow">{{ scope.row.category ? scope.row.category.name : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Sub Category" min-width="130px">
              <template slot-scope="scope">
                <div class="trim-text-overflow">{{ scope.row.sub_category ? scope.row.sub_category.name : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="TITLE" prop="title" min-width="150px">
              <template slot-scope="scope">
                <div class="trim-text-overflow">
                  <span>{{ scope.row.title }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="VIDEO URL" min-width="180px">
              <template slot-scope="scope">
                <div class="trim-text-overflow">
                  <span>{{ scope.row.url }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="TYPE" min-width="100px">
              <template slot-scope="scope">
                <div class="d-inline-block indicator">
                  <span>{{ getUrlType(scope.row.url_type) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="STATUS" prop="status" min-width="100px">
              <template slot-scope="scope">
                <span :class="['status',getStatus(scope.row.status)]">
                  <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                  <span>{{ getStatus(scope.row.status) }}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="ACTIONS" width="100px">
              <div slot-scope="{ $index, row }" class="d-flex">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" @click="toggleEditVideoModal(row.id)" alt="">
                <img
                  class="pointer"
                  @click="handleDelete($index, row)"
                  src="/img/icons/buttons/delete.svg"
                  alt="Delete"
                />
              </div>
            </el-table-column>
          </el-table>
        </el-table-draggable>
      </div>

      <div class="modals">
        <remove-modal
          :loader="loaders.removeModal"
          :modal-text="removeModalText"
          :removeId="removeId"
          @onRemove="removeVideo"
          @onRemoveMultiple="deleteVideo"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {Table, TableColumn} from "element-ui";
const RemoveModal = () => import(/* webpackChunkName: "RemoveModal" */ "@/components/Modals/RemoveModal.vue");
import ElTableDraggable from 'element-ui-el-table-draggable';
import AddVideoModal from "./AddVideoModal";
import EditVideoModal from "./EditVideoModal";

export default {


  name: "Videos",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    ElTableDraggable,
    AddVideoModal,
    EditVideoModal,
  },
  data () {
    return {
      menuId:11,
      modals: {
        addVideoModal: false,
        editVideoModal: false
      },
      selectedRows: [],
      loaders: {
        videos: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      btnDraggable:'btnDraggable',
      response: {
        data: []
      },
      editId: ''
    }
  },
  created() {
    this.fetchVideos()
  },
  methods: {

    // This function accept the arguments and returns new order key
    assignNewOrderKey(oldOrderKey, prev, curr, next){
      let newOrderKey
      if (prev === '') {
        if (curr < next || next === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = next
        }
      } else if ( next === '') {
        if (curr > prev || prev === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = prev
        }
      } else if (curr < prev && curr < next) {
        newOrderKey = prev
      } else if (curr > prev && curr > next) {
        newOrderKey = next
      } else if (curr > prev && curr < next) {
        newOrderKey = oldOrderKey
      }
      return newOrderKey
    },

    // This function returns counts of 0 order keys
    orderCheck(list) {
      let orderCheckCounter = 0
      for (const item of list) {
        if (item.order_key === 0) {
          orderCheckCounter++
          if (orderCheckCounter > 1) { break }
        }
      }
      return orderCheckCounter;
    },

    //This function returns payload based on defined conditions
    assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event) {
      let payload
      if (orderCheckCounter > 1) {
        let orderKeyArray = []
        let order = 1;
        for (const item of event.list) {
          orderKeyArray.push({ id: item.id, order_key: order }) // Array having order set from Event
          order++
        }
        payload = { orderKeyArray }
      } else {
        payload = { id: event.targetObject.id, newOrderKey, oldOrderKey }
      }
      return payload
    },

    // This function updates the Order of the table list
    async updateSortList(event){
      let vm = this
      let oldOrderKey = event.targetObject.order_key
      let newOrderKey
      let currIndex = event.list.findIndex(item => item.id === event.targetObject.id)
      let curr = event.list.find(item => item.id === event.targetObject.id).order_key
      let prev = (event.list[currIndex - 1]) ? event.list[currIndex - 1].order_key : ''
      let next = (event.list[currIndex + 1]) ? event.list[currIndex + 1].order_key : ''

      // This function assigns new order key based on defined conditions
      newOrderKey = this.assignNewOrderKey(oldOrderKey, prev, curr, next)

      let orderCheckCounter = 0 // Counter to check number of 0's in order

      // Pass list and counter to get counts of 0 order key
      orderCheckCounter = this.orderCheck(event.list)

      // Check if item is not drag to other position
      if (newOrderKey === oldOrderKey && orderCheckCounter < 2) { return }

      let payload // Initializing payload

      //This function assigns the payload based on defined conditions
      payload = this.assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event)

      const dispatchPayload = {
        contentMenu: 'our-videos',
        payload
      }

      try{
        const response = await vm.$store.dispatch('_updateSortList', dispatchPayload); // Dispatch Api call to update sort list
        vm.response.data = response.data.data
      }
      catch(error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Videos',
          message: message
        });
      }
    },

    // After adding/editing video, this function is called that fetch videos and update it
    updateVideos () {
      this.fetchVideos()
    },

    // Fetch all the videos from the relevant table and set data from response
    fetchVideos() {
      let vm = this;
      vm.loaders.videos = true
      const config = {
        params: this.params
      }
      axios.get(this.$store.getters.getBaseUrl + '/api/content/our-videos', config)
        .then((response) => {
          vm.response.data = response.data.data;
          vm.copyData = response.data.data
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.videos = false);
    },

    // Function to show the Add Video Modal
    toggleAddVideoModal() {
      this.$store.commit('showAddVideoModal');
    },

    // Function to show the Edit Video Modal, and also set the editId
    toggleEditVideoModal(id) {
      this.editId = id
      this.$store.commit('showEditVideoModal');
    },

    // This prompts the Delete Modal
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    // Return status as Active/Inactive based on value
    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    // Return UrlType as Url/Video based on value
    getUrlType(url_type) {
      if (url_type === 0) {
        return 'Url'
      }
      return 'Video';
    },

    // Function that handle the Active button event
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    // Function that handle the Deactivate button event
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    // Set the status to true or false based on passed value and store it in the backend
    statusUpdateRequest(ids, status) {
      this.loaders.videos = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/content/our-videos/toggle-status`, payload)
        .then((response) => {
          this.removeId = []
          this.fetchVideos()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.videos = false
        })
    },

    // On multi-selecting the rows, it stores all ID's in selectedRows
    videosMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    // Function to show Remove Modal
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Video.';
      this.removeId = row.id;
    },

    // Function to show Remove Modal
    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these Videos.';
      this.removeId = this.extractSelectedIds;
    },

    // This function passes the ID of Video to be removed
    removeVideo(id) {
      this.deleteRequest(id)
    },

    // This function passes the ID of Video to be removed
    deleteVideo() {
      this.deleteRequest(this.removeId)
    },

    // Delete Video request and update the Videos in Table
    deleteRequest(data) {
      this.loaders.videos = true
      this.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/our-videos/destroy`, payload)
        .then((response) => {
          this.removeId = [];
          this.fetchVideos();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false;
          this.loaders.videos = false;
        })
    },
  },
  computed: {
    // Extract selected ID's from the Table list
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>

.indicator {
  height: 7px;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.btnDraggable {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  cursor: grabbing;
  margin-left: 0;
}

.handle {
  cursor: grabbing;
}
</style>
