<template>
  <modal v-loading="loaders.video && loaders.subCategory && loaders.category" :show.sync="$store.state.modals.editVideo">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Edit Video</h1>
    <validation-observer ref="editVideoForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          type="text"
          :rules="{required: true}"
          v-model="response.video.title"
          name="title"
          label="Title*"
          placeholder="Title">
        </base-input>
        <base-input name="type" label="Category">
          <el-select
            class="w-100"
            label="Category"
            v-model="response.video.our_video_category_id"
            @change="onChangeCategory"
          >
            <el-option
              v-for="option in response.categories"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id + option.name">
            </el-option>
          </el-select>
        </base-input>
        <base-input name="type" label="Sub Category">
          <el-select
            class="w-100"
            label="Category"
            v-model="response.video.our_video_sub_category_id"
          >
            <el-option
              v-for="option in response.subCategories"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id + option.name">
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Type*">
          <el-select
            class="w-100"
            label="Type*"
            @change="response.video.url = ''"
            v-model="response.video.url_type">
            <el-option
              v-for="option in dropdowns.type"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
        <base-input v-if="response.video.url_type === 0"
                    type="url"
                    :rules="{required: true}"
                    v-model="response.video.url"
                    name="url"
                    label="Video Url*"
                    placeholder="Video Url">
        </base-input>
        <div v-else class="mb-3">
          <label class="form-control-label">Video Url*</label>
          <textarea id="textArea"
                    v-model="response.video.url"
                    class="w-100 text-area-input"
                    placeholder="Video Url"
                    required>
          </textarea>
        </div>
        <base-input label="Status*">
          <el-select
            class="w-100"
            label="Status*"
            v-model="response.video.status">
            <el-option
              v-for="option in dropdowns.status"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>

        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="$store.commit('hideEditVideoModal')">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Update
          </base-button>
        </div>

      </form>
    </validation-observer>
  </modal>
</template>

<script>
import swal from "sweetalert2";

export default {
  name: "EditVideoModal",
  props: ['id','menuId'],
  data () {
    return {
      editId: '',
      loaders: {
        video: false,
        subCategory:false,
        category:false,
      },
      response: {
        video: [],
        categories:[],
        subCategories:[]
      },
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive'
          },
        ],
        type: [{
          value: 0,
          label: 'Url'
        },
          {
            value: 1,
            label: 'Video',
          },
        ]
      }
    }
  },
  watch: {

    // Watcher to check if ID changes then call the fetch Api to get the relevant ID data
    id: function (newVal, oldVal) {
      if(newVal != null) {
        this.editId = newVal
        this.fetchVideo()
      }
    }
  },

  mounted() {
    this.fetchCategories();
  },

  methods: {
    // Function to submit changes to database and get the response
    submitForm() {
      this.editId = null
      let vm = this
      vm.loaders.video = true
      axios.post(this.$store.getters.getBaseUrl + '/api/content/our-videos/update', vm.response.video)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          this.$refs.editVideoForm.reset();
          vm.showSwal('success','Always check to see how your changes look in the Baritastic app.');
          this.$emit('updateVideosEvent')
        })
        .catch((error) => console.error(error.response))
        .finally(() => {
          vm.loaders.video = false
          this.$store.commit('hideEditVideoModal')
        });
    },

    // Fetch the Video data based on the ID
    fetchVideo() {
      let vm = this
      vm.loaders.video = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/our-videos/' + vm.editId)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.video = response.data.data;
          vm.fetchSubCategories(vm.response.video.our_video_category_id ? vm.response.video.our_video_category_id : null);
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.video = false);
    },

    fetchSubCategories(id) {
      if (id) {
        let vm = this;
        vm.loaders.subCategory = true
        let payLoad = {
          menuId: vm.menuId,
          categoryId: id,
        }

        vm.$store.dispatch('contentSubCategory/_APIGetAll', payLoad).then(response => {
          vm.response.subCategories = response.data.data;
        }).catch(error => {
          console.error(error.response);
        }).finally(() => vm.loaders.subCategories = false);
      }
    },

    fetchCategories(){
      let vm = this;
      vm.loaders.category = true
      let payLoad = {
        menuId: vm.menuId,
      }
      vm.$store.dispatch('contentCategory/_APIGetAll', payLoad).then(response => {
        vm.response.categories= response.data.data;
      }).catch(error => {console.error(error.response);
      }).finally(() => vm.loaders.category = false);
    },

    onChangeCategory(category){
      this.response.video.our_video_sub_category_id = null;
      if (category == 0) {
        this.response.subCategories = [];
      }
      else {
        this.fetchSubCategories(category);
      }
    },
    // Return UrlType as Url/Video based on value
    getUrlType (UrlType) {
      if (UrlType === 0) {
        return 'Url'
      } else {
        return 'Video'
      }
    }
  },
}
</script>

<style scoped>

.text-area-input {
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

</style>
